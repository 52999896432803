<template>
  <h1>Klinik-Lösung</h1>

  Wir liefern <strong>praxisbezogene</strong>

  <ul>
    <li>
      Klinik-Prozessberatung sowie Klinik-Change-Management (ganzheitlicher
      Ansatz)
    </li>
    <li>
      Identifikation strategischer Handlungsbedarfe in Kliniken
    </li>
  </ul>

  Die <strong>ganzheitliche</strong> Betrachtung sichert die Klinikpotenziale
  und die <strong>Erreichung</strong> des geplanten Kliniknutzens.
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Lösung',
  components: {},
})
</script>
